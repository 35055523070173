<template>
    <Card class="user_view" dis-hover>
        <div slot="title">{{ title }}</div>
        <Card title="基础信息" dis-hover>
            <div class="relative width_100_percent flex flex_wrap align_start">
                <div>
                    <img class="border_radius_50percent header" :src="formData.userImg" />
                </div>
                <div class="ml_20">
                    <div class="item">{{ formData.nickname || '' }}</div>
                    <div class="item">手机号码：{{ formData.mobile || '' }}</div>
                </div>
                <div class="ml_50">
                    <div class="item">注册时间 {{ formData.ctime || '' }}</div>
                    <div class="item">最近登陆时间：{{ formData.last_login_time || '' }}</div>
                    <div class="item">最近购买时间：{{ formData.pay_time || '' }}</div>
                </div>
            </div>
        </Card>
        <Card class="mt_20" title="交易统计" dis-hover>
            <div class="relative width_100_percent flex flex_wrap account_container">
                <div class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">累计订单数</div>
                    <div class="fontsize_38 font_weight">{{ formData.orderCount || 0 }}</div>
                    <div class="mCe btn" @click="onQueryOrderAll">查看订单数量</div>
                </div>
                <div class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">累计消费金额（元）</div>
                    <div class="fontsize_38 font_weight">{{ formData.payPrice | cny }}</div>
                </div>
                <div class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">客单价（元）</div>
                    <div class="fontsize_38 font_weight">{{ formData.onlyPrice | cny }}</div>
                </div>
                <div class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">累计退货订单数</div>
                    <div class="fontsize_38 font_weight">{{ formData.reFundCount || 0 }}</div>
                </div>
                <div class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">累计退货金额（元）</div>
                    <div class="fontsize_38 font_weight">{{ formData.reFundPrice | cny }}</div>
                </div>
            </div>
        </Card>

        <Card class="mt_20" title="服务统计" dis-hover v-if="(formData.service || []).length > 0">
            <div class="relative width_100_percent flex flex_wrap account_container">
                <div v-for="info in formData.service || []" :key="encodeURIComponent(info.sid)" class="flex column align_center justify_center account_item" :style="{ '--border-color': getRandomColor() }">
                    <div class="label">{{ info.sid }}</div>
                    <div class="fontsize_38 font_weight">{{ info.nums }}</div>
                    <!-- <div class="mCe btn" @click="onQueryOrderLogJiance">查看订单记录</div> -->
                </div>
            </div>
        </Card>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onBack">返回</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqUserView } from '@/lib/request/auth2';
import { getRandomColor } from '@/lib/util';
// import {  } from '@/lib/request/auth';

export default {
    name: 'userView',
    components: {
        //
    },
    data() {
        return {
            title: '用户详情',
            formData: {
                id: null,
                nickname: null,
                userImg: null,
                mobile: null,
                ctime: null,
                last_login_time: null,
                pay_time: null,
                orderCount: 0,
                payPrice: 0,
                onlyPrice: 0,
                reFundCount: 0,
                reFundPrice: 0,
                service: [],
            },
        };
    },
    mounted() {
        this.routeIdInject();
        this.getDetail();
    },
    watch: {},
    methods: {
        getRandomColor,
        //获取详情
        getDetail() {
            this.showAjaxLoading();
            reqUserView({ userId: this.formData.id })
                .then(res => {
                    this.dataInjectFormData(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //返回
        onBack() {
            this.$router.back();
        },
        //累计订单数-查看订单数量
        onQueryOrderAll() {
            this.fadeAlert('敬请期待');
        },
        //监测订单-查看订单记录
        onQueryOrderLogJiance() {
            this.fadeAlert('敬请期待');
        },
        //安装订单-查看订单记录
        onQueryOrderLogAnzhuang() {
            this.fadeAlert('敬请期待');
        },
    },
};
</script>
<style lang="less" scoped>
.user_view {
    .header {
        width: 70px;
        height: 70px;
    }
    .item {
        padding: 5px 0;
    }
    .account_container {
        gap: 20px;
        .account_item {
            position: relative;
            width: 250px;
            height: 150px;
            border: 2px var(--border-color) solid;
            border-radius: 8px;
            .label {
                position: absolute;
                left: 10px;
                top: 10px;
            }
            .btn {
                bottom: 10px;
                cursor: pointer;
                text-align: center;
                color: blue;
            }
        }
    }
}
</style>
